<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="name" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动奖池配置.名称')}}<template slot="title">{{$t('抽奖活动奖池配置.名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.name" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动奖池配置.名称')" />
      </a-form-model-item>



      <!--<a-form-model-item prop="picture" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('抽奖活动奖池配置.图片')}}<template slot="title">{{$t('抽奖活动奖池配置.图片')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.picture" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动奖池配置.图片')" />-->
      <!--</a-form-model-item>-->





      <a-form-model-item :label="$t('抽奖活动奖池配置.图片')" prop="picture" >
        <a-upload
          name="picture"
          listType="picture-card"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.picture"
            :src="form.picture"
            alt="picture"
            style="height: 102px; width: 102px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 351 x 175</span>
      </a-form-model-item>


      <a-form-model-item prop="type" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动奖池配置.奖励类型')}}<template slot="title">{{$t('抽奖活动奖池配置.奖励类型')}}</template>
          </a-tooltip>
        </span>
        <a-select :placeholder="$t('通用.输入.请选择')+$t('抽奖活动奖池配置.奖励类型')" v-model="form.type" allowClear>
          <a-select-option  :value="1">{{$t('奖励类型.谢谢惠顾')}}</a-select-option>
          <a-select-option  :value="2">{{$t('奖励类型.平台币')}}</a-select-option>
          <a-select-option  :value="3">{{$t('奖励类型.商品')}}</a-select-option>
          <a-select-option  :value="4">{{$t('奖励类型.优惠券')}}</a-select-option>
        </a-select>
      </a-form-model-item>

      <span v-if="form.type == '2'">
         <a-form-model-item prop="gold" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动奖池配置.奖励金额')}}<template slot="title">{{$t('抽奖活动奖池配置.奖励金额')}}</template>
          </a-tooltip>
        </span>
        <a-input-number v-model="form.gold"  :placeholder="$t('通用.输入.请输入')+$t('抽奖活动奖池配置.奖励金额')" />
      </a-form-model-item>
      </span>


      <span v-if="form.type == '3'">
          <!--选择商品-->
          <a-form-model-item :label="$t('通用.文本.选择商品')" prop="goodsId">
            <a-button type="dashed" @click="openProdSelect" danger>
              <span style="color: dodgerblue"> {{$t('通用.文本.选择商品')}}</span>
            </a-button>
          </a-form-model-item>
          <!-- 数据展示 -->
          <a-table
            :loading="goodsLoading"
            :scroll="{ x: '160%' }"
            rowKey="id"
            :size="tableSize"
            :columns="goodsColumns"
            :data-source="form.goodsList"
            :pagination="false"
            :bordered="tableBordered">
            <span slot="createTime" slot-scope="text, record">
              {{ parseTime(record.createTime) }}
            </span>
            <template slot="picture" slot-scope="text, record">
              <div>
                <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
              </div>
            </template>

            <span slot="categoryId" slot-scope="text, record">
                {{getCategoryNameById(record.categoryId)}}
            </span>
            <span slot="brandId" slot-scope="text, record">
                 {{getBrandNameById(record.brandId)}}
              </span>
            <span slot="status" slot-scope="text, record">
               <custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>
              </span>
          </a-table>
          <!--选择商品-->
      </span>

      <span v-if="form.type == '4'">
        <a-form-model-item prop="couponTypeId" >
          <span slot="label" >
            <a-tooltip>
              {{$t('抽奖活动奖池配置.优惠券')}}<template slot="title">{{$t('抽奖活动奖池配置.优惠券')}}</template>
            </a-tooltip>
          </span>
          <a-select :placeholder="$t('通用.输入.请选择')+$t('抽奖活动奖池配置.优惠券')" v-model="form.couponTypeId" allowClear>
            <a-select-option v-for="coupon in couponList" :value="coupon.id">{{ coupon.couponName }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </span>

      <a-form-model-item prop="sort" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动奖池配置.排序')}}<template slot="title">{{$t('抽奖活动奖池配置.排序')}}</template>
          </a-tooltip>
        </span>
        <a-input-number v-model="form.sort"  :placeholder="$t('通用.输入.请输入')+$t('抽奖活动奖池配置.排序')" />
      </a-form-model-item>

      <a-form-model-item prop="description" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动奖池配置.描述')}}<template slot="title">{{$t('抽奖活动奖池配置.描述')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.description" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动奖池配置.描述')" />
      </a-form-model-item>
      <!--<a-form-model-item prop="remark" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('抽奖活动奖池配置.备注')}}<template slot="title">{{$t('抽奖活动奖池配置.备注')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
      <!--</a-form-model-item>-->
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>

      <!--选择商品-->
      <radio-goods-select-form ref="RadioGoodsSelectForm" @goodsSelect="indexGoodsModalSelect"></radio-goods-select-form>
      <!--选择商品-->

    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLuckActivity, addLuckActivity, updateLuckActivity } from '@/api/luckActivity/luckActivity'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, generateRdStr, uploadObject, uploadOss} from "@/api/tool/oss";
import { pageCouponType,listCouponType, delCouponType } from '@/api/coupon/couponType'
//选择商品
import RadioGoodsSelectForm from "@/views/zb/seckill/modules/RadioSelectForm";
import {listCategory} from "@/api/goods/category";
import {allBrand} from "@/api/goods/brand";
//选择商品

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
    //选择商品
    RadioGoodsSelectForm
    //选择商品
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      couponList:[],
      //选择商品
      categoryList:[],
      goodsColumns: [
        /*{
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          width: 100,
          fixed: 'left',
        },*/
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 180,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },
      ],
      //选择商品

      // 表单参数
      form: {
        //选择商品
        goodsList: [],
        //选择商品
        goodsId: null,

        id: null,

        name: null,

        picture: null,

        type: null,

        gold: null,

        description: null,

        createTime: null,

        remark: null,

        sort: null,

        couponTypeId: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: this.$t('抽奖活动奖池配置.名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        type: [
          { required: true, message: this.$t('抽奖活动奖池配置.奖励类型')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        sort: [
          { required: true, message: this.$t('抽奖活动奖池配置.排序')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
    //选择商品
    this.getCateList();
    this.getBrandList();
    //选择商品

    this.getCouponList();
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        //选择商品
        goodsList: [],
        //选择商品
        goodsId: null,
        id: null,
        name: null,
        picture: null,
        type: null,
        gold: null,
        description: null,
        createTime: null,
        remark: null,
        sort: null,
        couponTypeId: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLuckActivity({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },

    //选择商品
    indexGoodsModalSelect(records) {
      this.form.goodsId = records[0].id
      this.form.goodsList = records
      console.log('商品选择了',this.form.goodsList)
    },
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.goodsId]);
    },
    //选择商品

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateLuckActivity(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addLuckActivity(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },


     //上传图片
    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'activity'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
        _this.$set(_this.form, 'picture', res)
      _this.$message.success(this.$t('通用.文本.上传成功'),)
    })
    })
    },
    coverDetailImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'activity'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
        _this.$set(_this.form, 'posterPic', res)
      _this.$message.success(this.$t('通用.文本.上传成功'),)
    })
    })
    },
    //上传图片

    //选择商品
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.goodsId]);
    },

    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },
    getCateList() {
      listCategory().then(response => {
        this.categoryList = response.data;
      const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
      cate.children = this.handleTree(response.data, 'id','pid')
      this.cateOptions.push(cate)
    })
    },
    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
    })
    },
    //选择商品



    /** 查询优惠券类型列表 */
    getCouponList () {
      listCouponType().then(response => {
        this.couponList = response.data
       })
    },


  }
}
</script>
