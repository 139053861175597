import request from '@/utils/request'


// 查询抽奖活动奖池配置列表
export function listLuckActivity(query) {
  return request({
    url: '/luckActivity/luckActivity/list',
    method: 'get',
    params: query
  })
}

// 查询抽奖活动奖池配置分页
export function pageLuckActivity(query) {
  return request({
    url: '/luckActivity/luckActivity/page',
    method: 'get',
    params: query
  })
}

// 查询抽奖活动奖池配置详细
export function getLuckActivity(data) {
  return request({
    url: '/luckActivity/luckActivity/detail',
    method: 'get',
    params: data
  })
}

// 新增抽奖活动奖池配置
export function addLuckActivity(data) {
  return request({
    url: '/luckActivity/luckActivity/add',
    method: 'post',
    data: data
  })
}

// 修改抽奖活动奖池配置
export function updateLuckActivity(data) {
  return request({
    url: '/luckActivity/luckActivity/edit',
    method: 'post',
    data: data
  })
}

// 删除抽奖活动奖池配置
export function delLuckActivity(data) {
  return request({
    url: '/luckActivity/luckActivity/delete',
    method: 'post',
    data: data
  })
}
